import React from 'react';

import Container from './Container';

const images = [
  '/images/space-1.jpg',
  '/images/space-2.jpg',
  '/images/space-3.jpg',
  '/images/space-4.jpg',
  '/images/space-5.jpg',
  '/images/space-6.jpg',
  '/images/space-7.jpg',
  '/images/space-8.jpg',
  '/images/space-9.jpg',
  '/images/space-10.jpg'
];

function App() {
  const image = images[Math.floor(Math.random() * images.length)];
  return (
    <Container image={image} />
  );
}

export default App;
