import React, { useState, useEffect } from 'react';
import Head from './Head';

function Container({ image }) {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const [position, setPosition] = useState({ x: windowWidth / 2, y: windowHeight / 2});
  const [direction, setDirection] = useState({ x: Math.random() * 20, y: Math.random() * 20 });
  const { x, y } = position;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition(({ x, y }) => {
        const newX = x + direction.x;
        const newY = y + direction.y;

        return {
            x: newX,
            y: newY,
        };
      });
    }, 100);

    return () => clearInterval(intervalId);
  }, [direction, windowHeight, windowWidth]);

    useEffect(() => {
        if (x >= windowWidth || x <= 0) {
            setDirection(({ x, y }) => ({
                x: -x,
                y
            }));
        }

        if (y >= windowHeight || y <= 0) {
            setDirection(({ x, y }) => ({
                x,
                y: -y
            }));
        }
    }, [x, windowWidth, y, windowHeight]);

  return (
    <div className="container" style={{
      backgroundImage: `url(${image})`
    }}>
      <Head style={{
        transform: `translate(calc(${Math.floor(x)}px - 50%), calc(${Math.floor(y)}px - 50%)`,
      }}/>
    </div>
  );
}

export default Container;
