import React from 'react';

function Head(props) {
  return (
    <div {...props} className="head">
        <a href="https://github.com/EmericW" target="_blank" rel="noopener noreferrer"><img src="/images/face.jpg" alt="face"/></a>
    </div>
  );
}

export default Head;
